.privacy-policy {
  p {
    margin-bottom: 10px;

    #list {
      list-style: none;

      .list-item:before {
        content: "\2022";
        margin-right: 5px;
      }
    }
  }
}
