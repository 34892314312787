@import "../../styles/abstracts/variables/variables";

%container {
  width: 100%;
  margin: 0 auto;
  padding: 0 $spacing-lg;

  @media (min-width: $breakpoint-md) {
    padding: 0 $spacing-xxxl;
  }

  .modal & {
    @media (max-width: $breakpoint-sm) {
      max-width: 100%;
      padding: 0;
    }
  }
}

.container-xxs {
  @extend %container;

  max-width: 406px + ($spacing-lg * 2);

  @media (min-width: $breakpoint-md) {
    max-width: 406px + ($spacing-xxxl * 2);
  }
}

.container-xs {
  @extend %container;

  max-width: 500px + ($spacing-lg * 2);

  @media (min-width: $breakpoint-md) {
    max-width: 500px + ($spacing-xxxl * 2);
  }
}

.container-sm {
  @extend %container;

  max-width: 564px + ($spacing-lg * 2);

  @media (min-width: $breakpoint-md) {
    max-width: 564px + ($spacing-xxxl * 2);
  }
}

.container-md {
  @extend %container;

  max-width: 624px + ($spacing-lg * 2);

  @media (min-width: $breakpoint-md) {
    max-width: 624px + ($spacing-xxxl * 2);
  }
}

.container-lg {
  @extend %container;

  max-width: 684px + ($spacing-lg * 2);

  @media (min-width: $breakpoint-md) {
    max-width: 684px + ($spacing-xxxl * 2);
  }
}

.container-xl {
  @extend %container;

  max-width: 1380px + ($spacing-lg * 2);

  @media (min-width: $breakpoint-md) {
    max-width: 1380px + ($spacing-xxxl * 2);
  }
}

.container-fluid {
  @extend %container;

  max-width: 100%;
}
