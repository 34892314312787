@import "../../styles/main";

.react-tel-input {
  display: flex;
  flex-direction: column;

  & + & {
    margin-top: $spacing-sm;
  }
}

.react-tel-input :disabled {
  cursor: not-allowed;
}

.react-tel-input .hide {
  display: none;
}

.react-tel-input .v-hide {
  visibility: hidden;
}

.react-tel-input .form-control {
  @extend %field-control;

  .input-append-icon ~ & {
    padding-right: 50px;
  }

  .input-prepend-icon ~ & {
    padding-left: 50px;
  }
}

.react-tel-input .form-control.invalid-number {
  border: 1px solid #d79f9f;
  background-color: #faf0f0;
  border-left-color: #cacaca;
}

.react-tel-input .form-control.invalid-number:focus {
  border: 1px solid #d79f9f;
  border-left-color: #cacaca;
  background-color: #faf0f0;
}

.react-tel-input .invalid-number-message {
  margin-top: $spacing-xs;
  font-size: $font-sm;
  color: $danger-dark;
}

.react-tel-input .special-label {
  font-size: $font-sm;
  margin-bottom: $spacing-sm;
  color: $secondary-text-color;
}
