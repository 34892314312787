@import "../../styles/abstracts/variables/variables";

.card {
  background-color: $light;
  border-radius: $radius-lg;
  border: 1px solid #e7e7ed;

  &.is-collapsible {
    overflow: hidden;
  }

  & + & {
    margin-top: $spacing-lg;

    @media (min-width: $breakpoint-md) {
      margin-top: $spacing-xxl;
    }
  }
}

.card-info {
  padding: $spacing-xl;

  @media (min-width: $breakpoint-md) {
    padding: $spacing-xxxl;
  }
}

.card-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $grey-dark;
  padding-bottom: $spacing-md;
  margin-bottom: $spacing-md;
}

.card-controls {
  display: flex;
  margin-left: $spacing-sm;
}

.card-content {
  width: 100%;
  font-weight: 500;

  @media (min-width: $breakpoint-md) {
    display: table;
  }

  .schedule & {
    @media (min-width: $breakpoint-md) {
      display: block;
    }

    @media (min-width: $breakpoint-xl) {
      display: table;
    }
  }

  .card-content-cell:first-child {
    margin-right: 20px;
  }
}

.card-content-cell {
  .schedule & {
    @media (min-width: $breakpoint-md) {
      display: block;
      width: auto;
    }

    @media (min-width: $breakpoint-xl) {
      display: table-cell;
      width: 0%;
    }
  }

  & + & {
    margin-top: $spacing-md;

    @media (min-width: $breakpoint-md) {
      margin-top: $spacing-md;
    }

    .schedule & {
      @media (min-width: $breakpoint-md) {
        margin-top: $spacing-md;
      }

      @media (min-width: $breakpoint-xl) {
        margin-top: 0;
      }
    }
  }

  @media (min-width: $breakpoint-md) {
    display: table-cell;
    width: 0%;
  }
}

.card-content-label {
  display: block;
  margin-bottom: $spacing-xs;
  color: $secondary-text-color;
}

.card-content-value {
  display: block;
  font-size: $font-md;
}

.card-details {
  transition: 1s ease;
  max-height: 850px;
  overflow: hidden;

  .is-collapsible.is-collapsed & {
    max-height: 0;
    transition: $transition;
  }
}
