@import "../../styles/abstracts/variables/variables";

.field {
  display: flex;
  flex-direction: column;

  & + & {
    margin-top: $spacing-sm;
  }
}

.field-control {
  position: relative;
  color: $main-text-color;
}

.field-label {
  display: flex;
  align-items: center;
  font-size: $font-sm;
  font-weight: 500;
  margin-bottom: $spacing-xs;
  color: $secondary-text-color;
}

.field-controls {
  margin-left: auto;
}

.field-error {
  margin-top: $spacing-xs;
  font-size: $font-sm;
  color: $danger-dark;
  text-align: left;
}

.field-hint {
  margin-top: $spacing-xs;
  font-size: $font-sm;
  color: $secondary-text-color;
}

.field-required {
  margin-left: $spacing-sm;
  color: $danger-medium;
}
