@import "../../styles/abstracts/variables/variables";
@import "../../styles/base/helpers";

.textarea {
  resize: none;
}

.textarea-control {
  @extend %field-control;

  height: 210px;
}
