@import "../../styles/abstracts/variables/variables";

%link-icon {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;

  svg {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

%link {
  display: flex;
  align-items: center;
  color: $main-text-color;
  text-decoration: none;
  transition: $transition;
  cursor: pointer;
  font-weight: bold;
  white-space: nowrap;
}

.link {
  @extend %link;

  &:hover {
    color: $primary-medium;
  }

  &:active {
    color: $primary-dark;
  }
}

.link-inline {
  display: inline;
}

.link-primary {
  color: $primary;
}

.link-danger {
  color: $danger-light;

  &:hover {
    color: $danger-medium;
  }

  &:active {
    color: $danger-dark;
  }
}

.link-text {
  color: $main-text-color;
}

.link-outlined {
  color: inherit;
  font-weight: 500;
  text-decoration: underline;
}

.link-label {
  .link-prepend-icon ~ & {
    margin-left: $spacing-sm;
  }

  .link-append-icon ~ & {
    margin-right: $spacing-sm;
  }
}

.link-prepend-icon {
  @extend %link-icon;
}

.link-append-icon {
  @extend %link-icon;
}
