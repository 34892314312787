.panel {
  margin: (-$spacing-xxl) (-$spacing-lg);
  padding: $spacing-xxxl 0;
  background: $light;

  @media (min-width: $breakpoint-sm) {
    margin: 0;
    border-radius: $radius-xl;
  }
}
