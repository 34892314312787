@import "../../styles/abstracts/variables/variables";

.section {
  & + & {
    margin-top: $spacing-xxl;
  }
}

.section-content {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
}

.section-heading {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: $spacing-xxl;

  @media (min-width: $breakpoint-sm) {
    flex-wrap: nowrap;
    justify-content: space-between;
  }
}

.section-control {
  z-index: 4;

  & + & {
    margin-top: $spacing-md;

    @media (min-width: $breakpoint-sm) {
      margin-left: $spacing-md;
      margin-top: 0;
    }
  }
}

.section-search {
  width: 100%;
  margin-top: $spacing-md;
  order: 3;

  @media (min-width: $breakpoint-sm) {
    width: auto;
    margin-left: $spacing-md;
    margin-top: 0;
    order: 0;
  }
}

.section-title {
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: $spacing-md;
  line-height: 44px;
}
