@import "../../styles/abstracts/variables/variables";

$table-border: #e9e9e9;
$table-cell-width: 262px;
$table-cell-width-sm: 222px;

%sticky-cell {
  min-width: 102px;
  padding: $spacing-xs ($spacing-xs + $spacing-md);
  position: sticky;
  left: 0;
  z-index: 2;
  vertical-align: top;

  @media (min-width: $breakpoint-md) {
    flex-basis: 120px;
    width: 120px;
    min-width: 120px;
    max-width: 120px;
  }
}

.schedule {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-width: 0%;

  & > .container-fluid {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  @media (max-width: $breakpoint-md-max) {
    padding: $spacing-xxl 0;
  }
}

.schedule-filters-control {
  flex: 1 1 auto;
  width: 100%;
  margin-top: $spacing-xxl;

  @media (min-width: $breakpoint-xs) {
    flex: 0 0 auto;
    width: auto;
    margin-top: 0;
  }

  @media (min-width: $breakpoint-sm) {
    display: none;
  }
}

.schedule-table {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 100%;
  margin: 0;
}

.schedule-content {
  //display: flex;
  //flex-direction: column;
  display: table;
  height: 100%;

  .month & {
    width: 100%;
  }
}

.schedule-header {
  display: table-header-group;
}

.schedule-body {
  //display: flex;
  //flex-direction: column;
  display: table-row-group;
  height: 100%;
}

.schedule-row {
  width: 100%;
  flex: 1 1 auto;
  min-height: 0%;

  //display: flex;
  display: table-row;

  .day &,
  .week & {
    height: calc(100% / 7);
  }
}

.schedule-cell {
  //display: flex;
  //flex-direction: column;
  //flex: 1 0 auto;
  //flex-grow: 0;
  //flex-basis: calc(100% / 7);
  display: table-cell;
  min-height: 40px;
  padding: $spacing-xs;
  min-width: $table-cell-width-sm;
  width: calc(100% / 3);
  position: relative;
  background-color: $light;

  @media (min-width: $breakpoint-md) {
    min-width: $table-cell-width;
  }

  &.is-heading {
    line-height: 32px;
    padding: $spacing-xs ($spacing-xs + $spacing-sm);

    @media (min-width: $breakpoint-md) {
      padding: $spacing-xs ($spacing-xs + $spacing-md);
    }
  }

  &.is-disabled {
    color: $grey-extradark;
  }

  &.is-selected {
    font-weight: bold;
    color: $primary;
  }

  &:not(:last-child) {
    border-right: 1px solid $table-border;
    border-left: 0;
  }

  &:last-child {
    border-left: 0;
  }

  .calendar-body & {
    cursor: pointer;
  }

  .month .schedule-body & {
    width: calc(100% / 7);
    min-width: calc(100% / 7);

    @media (max-width: $breakpoint-md-max) {
      height: 130px;
    }
  }

  .week .schedule-body & {
    @media (max-width: $breakpoint-md-max) {
      height: 68px;
    }
  }

  /* stylelint-disable no-descending-specificity */
  .week & {
    &:first-child {
      @extend %sticky-cell;
    }

    &:not(.is-heading):first-child {
      padding: ($spacing-xs + $spacing-sm) ($spacing-xs + $spacing-md);
    }
  }

  .day & {
    height: auto;

    &:first-child {
      @extend %sticky-cell;
    }
  }

  .day .table-header &,
  .week .table-header & {
    &:first-child {
      z-index: 4;
    }
  }
  /* stylelint-enable no-descending-specificity */
}

.schedule-cell-content {
  height: 100%;
  display: flex;
  flex-direction: column;

  .day & {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: $spacing-xs;
    z-index: 1;
    height: auto;
  }
}

.schedule-day {
  padding: $spacing-xs $spacing-sm $spacing-sm;

  @media (min-width: $breakpoint-md) {
    padding: $spacing-xs $spacing-md $spacing-sm;
  }

  .month & {
    @media (max-width: $breakpoint-md-max) {
      white-space: normal;
    }
  }
}

.schedule-heading-short {
  @media (min-width: $breakpoint-lg) {
    display: none;
  }
}

.schedule-heading {
  @media (max-width: $breakpoint-md-max) {
    display: none;
  }
}

.schedule-inner {
  .schedule.is-scrollable & {
    @media (min-width: $breakpoint-md) {
      padding: $spacing-xxl 0;
      margin: -$spacing-xxl 0;
      overflow: auto;
    }
  }
}
