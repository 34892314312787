@import "../../../../styles/abstracts/variables/variables";

.legend {
  margin-bottom: $spacing-md;

  @media (min-width: $breakpoint-md) {
    display: flex;
    align-items: center;
    font-size: $font-md;
  }
}

.legend-title {
  font-weight: 500;
}

.legend-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: $spacing-xs (-$spacing-sm) 0;

  @media (min-width: $breakpoint-md) {
    margin-top: 0;
    margin-left: $spacing-lg;
  }
}

.legend-item {
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding: 3px $spacing-sm;

  @media (min-width: $breakpoint-md) {
    padding: 0 $spacing-sm;
  }
}

.legend-icon {
  display: block;
  width: 6px;
  height: 6px;
  margin-right: $spacing-xs;
  border-radius: $radius-xl;

  .legend-item.is-warning & {
    background-color: $warning-dark;
  }

  .legend-item.is-primary & {
    background-color: $primary;
  }

  .legend-item.is-default & {
    background-color: $secondary-text-color;
  }

  @media (min-width: $breakpoint-md) {
    width: 16px;
    height: 16px;
    margin-right: $spacing-xs;
    border-radius: $radius-xs;
  }
}
