@import "../../styles/abstracts/variables/variables";

.success {
  margin: auto;
  text-align: center;
}

.success-content {
  padding: 108px $spacing-xxxl;
}

.success-icon {
  width: 76px;
  height: 76px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto $spacing-xxl;
  color: $primary;

  svg {
    width: 100%;
    height: 100%;
  }
}

.success-title {
  margin-bottom: $spacing-xxl;
}

.success-text {
  margin-bottom: $spacing-xxxl;
}
