@import "../../styles/abstracts/variables/variables";
@import "../../styles/base/helpers";

.filter {
  height: 44px;
  width: 44px;
  border-radius: $radius-sm;
  border-color: $grey-medium;
}

.filter-icon {
  z-index: 1;

  &.select__value-container { /* stylelint-disable-line */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    color: $primary;
  }

  svg {
    display: block;
    flex: 0 0 auto;
    width: 100%;
    height: 100%;
  }

  input {
    position: absolute;
  }
}

.value {
  visibility: hidden;
}
