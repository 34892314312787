@import "../../styles/abstracts/variables/variables";

.checkbox {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  transition: $transition;

  &.is-disabled {
    cursor: default;
  }
}

.checkbox-mark {
  position: relative;
  width: $spacing-lg;
  height: $spacing-lg;
  border: 1px solid $grey-medium;
  border-radius: 2px;
  color: $light;

  .checkbox-input:disabled ~ & {
    background-color: $grey-medium;
    border-color: $grey-dark;
  }

  .checkbox-input:checked:not(:disabled) ~ & {
    border-color: $primary;
    background-color: $primary;
  }

  .checkbox-input:focus:not(:disabled) ~ &,
  .checkbox:hover .checkbox-input:not(:disabled) ~ & {
    border-color: $primary-medium;
  }

  .checkbox-input:focus:checked:not(:disabled) ~ &,
  .checkbox:hover .checkbox-input:checked:not(:disabled) ~ & {
    border-color: $primary-dark;
    background-color: $primary-dark;
  }

  svg {
    width: 100%;
    height: 100%;
  }
}

.checkbox-label {
  margin-left: $spacing-sm;
  line-height: 1;
}
