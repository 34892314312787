@import "../../../../styles/abstracts/variables/variables";

.slot {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: $spacing-sm $spacing-md;
  border-radius: $radius-sm;
  overflow: hidden;
  height: 100%;
  flex: 1 1 auto;
  font-size: $font-sm;
  font-weight: 400;
  color: $main-text-color;
  z-index: 1;
  cursor: default;
  transition: $transition;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    display: block;
    width: 3px;
    height: 100%;

    .month & {
      @media (max-width: $breakpoint-md-max) {
        position: relative;
        width: 8px;
        height: 8px;
        margin-bottom: $spacing-xs;
        border-radius: 100%;
      }
    }
  }

  &.is-success {
    background: $primary-extralight;

    &::before {
      background: $primary;
    }
  }

  &.is-info {
    background: $info-extralight;

    &::before {
      background: $info-dark;
    }
  }

  &.is-warning {
    background: $warning-extralight;

    &::before {
      background: $warning-dark;
    }
  }

  &.is-default {
    background: $grey-light;
    color: $secondary-text-color;

    &::before {
      background: $grey-extradark;
    }
  }

  &.is-clickable {
    cursor: pointer;

    &:hover {
      z-index: 1;
      box-shadow: 0 0 0 1px rgb(152 161 179 / 10%), 0 15px 35px -5px rgb(17 24 38 / 20%), 0 5px 15px rgb(0 0 0 / 8%);
    }
  }

  .month & {
    padding: $spacing-sm;

    @media (min-width: $breakpoint-md) {
      padding: $spacing-sm $spacing-md;
    }
  }
}

.slot-label {
  display: block;
  width: 100%;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;

  .month & {
    display: none;

    @media (min-width: $breakpoint-lg) {
      display: block;
      font-weight: 500;
    }
  }

  .week & {
    font-weight: 500;
  }
}

.slot-label-short {
  font-weight: bold;

  .month & {
    @media (min-width: $breakpoint-lg) {
      display: none;
    }
  }
}

.slot-details-row {
  display: flex;
  align-items: center;
}

.slot-details-title {
  font-weight: 500;
}

.slot-details-info {
  color: $secondary-text-color;
}

.slot-details-label {
  margin-left: $spacing-xs;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.slot-details-type {
  font-size: $font-xs;
}

.slot-details-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
}

.slot-events {
  display: none;
  color: $secondary-text-color;

  @media (min-width: $breakpoint-lg) {
    display: block;
  }
}

.slot-events-item {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
