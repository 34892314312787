$main-text-color: #000;
$secondary-text-color: #667085;
$light: #fff;

//danger
$danger-extralight: #ffe7e7;
$danger-light: #f26563;
$danger-medium: #e5615f;
$danger-dark: #ca4f4e;

//warning
$warning-extralight: #fffaeb;
$warning-light: #fbd5a8;
$warning-medium: #f7b757;
$warning-dark: #b76e00;

//success
$success-extralight: #ecfdf3;
$success-light: #acecca;
$success-medium: #72dfa5;
$success-dark: #2bbb6e;

//info
$info-extralight: #e8f4fd;
$info-light: #6baefc;
$info-medium: #1988e1;
$info-dark: #005aa3;

//primary
$primary: #008b6a;
$primary-medium: #006f55;
$primary-dark: #00533f;
$primary-light: #b0e9dc;
$primary-extralight: #d9f5ee;

//grey
$grey-extralight: #f1f3f3;
$grey-light: #eaebeb;
$grey-medium: #d0d0d0;
$grey-dark: #bac0d2;
$grey-extradark: #868fa0;
