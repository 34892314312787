@import "../../styles/abstracts/variables/variables";

%btn-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;

  svg {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}

%btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  padding: $spacing-sm $spacing-xxxl;
  font-size: $font-md;
  font-family: $font-family;
  font-weight: 700;
  line-height: 1;
  color: $main-text-color;
  border: 1px solid $grey-extralight;
  background-color: $grey-extralight;
  border-radius: $radius;
  transition: $transition;
  cursor: pointer;
}

.button {
  @extend %btn;

  &.is-disabled,
  &:disabled {
    background-color: $grey-dark;
    border-color: $grey-dark;
    color: $light;
    cursor: not-allowed;
  }

  & + & {
    margin-left: $spacing-sm;
  }

  &:hover:not(:disabled):not(.is-disabled) {
    background-color: $grey-light;
    border-color: $grey-light;
  }

  &:active:not(:disabled):not(.is-disabled) {
    background-color: $grey-medium;
    border-color: $grey-medium;
  }
}

//sizes
.button-small {
  padding: $spacing-sm 11px;
  font-size: $font-md;
  font-weight: 400;

  @media (min-width: $breakpoint-sm) {
    padding: $spacing-sm $spacing-md;
  }

  .table & {
    @media (max-width: $breakpoint-xs-max) {
      height: 34px;
      width: 34px;
      padding: $spacing-sm $spacing-sm;
    }
  }
}

.button-medium {
  font-size: $font-sm;
  font-weight: 500;
}

.button-large {
  width: 100%;
}

.button-static {
  width: 100%;

  @media (min-width: $breakpoint-sm) {
    width: auto;
    min-width: 135px;
  }

  @media (min-width: $breakpoint-md) {
    min-width: 168px;
  }
}

//colors
.button-primary {
  &:not(:disabled):not(.is-disabled) {
    background-color: $primary;
    border-color: $primary;
    color: $light;
  }

  &:hover:not(:disabled):not(.is-disabled) {
    background-color: $primary-medium;
    border-color: $primary-medium;
  }

  &:active:not(:disabled):not(.is-disabled) {
    background-color: $primary-dark;
    border-color: $primary-dark;
  }
}

.button-secondary {
  &:not(:disabled):not(.is-disabled) {
    background-color: $danger-light;
    border-color: $danger-light;
    color: $light;
  }

  &:hover:not(:disabled):not(.is-disabled) {
    background-color: $danger-medium;
    border-color: $danger-medium;
  }

  &:active:not(:disabled):not(.is-disabled) {
    background-color: $danger-dark;
    border-color: $danger-dark;
  }
}

.button-warning {
  &:not(:disabled):not(.is-disabled) {
    background-color: $warning-extralight;
    border-color: $warning-extralight;
    color: $warning-dark;
  }

  &:hover:not(:disabled):not(.is-disabled) {
    background-color: $warning-light;
    border-color: $warning-light;
    color: $warning-dark;
  }

  &:active:not(:disabled):not(.is-disabled) {
    background-color: $warning-medium;
    border-color: $warning-medium;
    color: $light;
  }
}

.button-success {
  &:not(:disabled):not(.is-disabled) {
    background-color: $success-extralight;
    border-color: $success-extralight;
    color: $primary;
  }

  &:hover:not(:disabled):not(.is-disabled) {
    background-color: $success-light;
    border-color: $success-light;
    color: $primary;
  }

  &:active:not(:disabled):not(.is-disabled) {
    background-color: $success-medium;
    border-color: $success-medium;
    color: $light;
  }
}

.button-danger {
  &:not(:disabled):not(.is-disabled) {
    background-color: $danger-extralight;
    border-color: $danger-extralight;
    color: $danger-medium;
  }

  &:hover:not(:disabled):not(.is-disabled) {
    background-color: $danger-light;
    border-color: $danger-light;
    color: $light;
  }

  &:active:not(:disabled):not(.is-disabled) {
    background-color: $danger-medium;
    border-color: $danger-medium;
    color: $light;
  }
}

.button-info {
  &:not(:disabled):not(.is-disabled) {
    background-color: $info-extralight;
    border-color: $info-extralight;
    color: $info-dark;
  }

  &:hover:not(:disabled):not(.is-disabled) {
    background-color: $info-light;
    border-color: $info-light;
    color: $light;
  }

  &:active:not(:disabled):not(.is-disabled) {
    background-color: $info-medium;
    border-color: $info-medium;
    color: $light;
  }
}

.button-link {
  height: auto;
  padding: 0;
  margin-top: 12px;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;

  &.is-disabled,
  &:disabled {
    background-color: transparent;
    border-color: transparent;
    color: $grey-dark;
  }

  .calendar &,
  .slot &,
  .filters-header & {
    margin-top: 0;
  }

  &:not(:disabled):not(.is-disabled) {
    background-color: transparent;
    border-color: transparent;
    color: $primary;
    cursor: pointer;
  }

  &:hover:not(:disabled):not(.is-disabled) {
    background-color: transparent;
    border-color: transparent;
    color: $primary-medium;
  }

  &:active:not(:disabled):not(.is-disabled) {
    background-color: transparent;
    border-color: transparent;
    color: $primary-dark;
  }
}

.button-label {
  white-space: nowrap;
  transition: $transition;

  .button-prepend-icon ~ & {
    margin-left: $spacing-sm;
  }

  .button-append-icon ~ & {
    margin-right: $spacing-sm;
  }

  .button-small & {
    display: none;

    @media (min-width: $breakpoint-md) {
      display: block;
    }
  }

  .button-small .button-prepend-icon ~ & {
    margin-left: $spacing-xs;
  }

  .button-small .button-append-icon ~ & {
    margin-right: $spacing-xs;
  }

  .button-large .button-prepend-icon ~ & {
    margin-left: $spacing-md;
  }

  .button-large .button-append-icon ~ & {
    margin-right: $spacing-md;
  }

  .button-small.button-success:not(:disabled):not(.is-disabled) &,
  .button-small.button-warning:not(:disabled):not(.is-disabled) &,
  .button-small.button-danger:not(:disabled):not(.is-disabled) &,
  .button-small.button-info:not(:disabled):not(.is-disabled) & {
    color: $main-text-color;
  }

  .button-small.button-success:active:not(:disabled):not(.is-disabled) &,
  .button-small.button-warning:active:not(:disabled):not(.is-disabled) &,
  .button-small.button-danger:hover:not(:disabled):not(.is-disabled) &,
  .button-small.button-danger:active:not(:disabled):not(.is-disabled) &,
  .button-small.button-info:hover:not(:disabled):not(.is-disabled) &,
  .button-small.button-info:active:not(:disabled):not(.is-disabled) & {
    color: $light;
  }
}

.button-prepend-icon,
.button-append-icon {
  @extend %btn-icon;
}
