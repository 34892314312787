@import "../../../../styles/abstracts/variables/variables";

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;

  @media (min-width: $breakpoint-md) {
    height: 56px;
  }
}

.logo-img {
  height: 100%;
}
