.row {
  display: flex;
  flex-wrap: wrap;
  margin: (-1 * $spacing-xl / 2) (-1 * $spacing-xxl / 2);

  & + & {
    margin-top: $spacing-xl / 2;
  }
}

%col {
  width: 100%;
  padding: ($spacing-xl / 2) ($spacing-xxl / 2);
}

.row [class^="col"] {
  @extend %col;
}

@media (min-width: $breakpoint-xxs) {
  @include col-width(xxs);
  @include col-offset(xxs);
}

@media (min-width: $breakpoint-xs) {
  @include col-width(xs);
  @include col-offset(xs);
}

@media (min-width: $breakpoint-sm) {
  @include col-width(sm);
  @include col-offset(sm);
}

@media (min-width: $breakpoint-md) {
  @include col-width(md);
  @include col-offset(md);
}

@media (min-width: $breakpoint-lg) {
  @include col-width(lg);
  @include col-offset(lg);
}
