@import "../../../../styles/abstracts/variables/variables";

.menu-toggler {
  display: none;
  cursor: pointer;

  @media (max-width: $breakpoint-md-max) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
