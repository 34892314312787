@import "../../styles/abstracts/variables/variables";

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgb(34 34 34 / 20%);
}

.modal-window {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  max-height: 95vh;
  overflow: auto;
  padding: $spacing-xl;
  background-color: $light;
  z-index: 1;

  @media (min-width: $breakpoint-sm) {
    height: auto;
    box-shadow: 0 4px 6px rgb(233 237 247 / 55%), 0 -1px 6px rgb(233 237 247 / 55%);
    border-radius: $radius-md;
  }
}

.modal-header {
  width: 100%;
  display: flex;
  border-bottom: 1px solid $grey-medium;
  padding-bottom: $spacing-md;
  margin-bottom: $spacing-md;
}

.modal-title {
  flex: 1 1 auto;
  margin-right: $spacing-lg;
}

.modal-body {
  .confirm & {
    padding-top: $spacing-lg;
    margin-bottom: $spacing-sm;
  }

  @media (min-width: $breakpoint-xs) {
    flex: 1 1 auto;
  }
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: $spacing-xxl;

  .button {
    @media (max-width: $breakpoint-md) {
      width: 50%;
    }
  }
}

.modal-close {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  cursor: pointer;
  transition: $transition;

  &:hover {
    color: $danger-medium;
  }
}
