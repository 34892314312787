@import "../../styles/abstracts/variables/variables";

%alert {
  display: flex;
  padding: $spacing-xl;
  color: $main-text-color;
  border-radius: $radius-xs;
  line-height: 20px;
}

.alert {
  @extend %alert;

  &.is-success {
    background-color: $success-extralight;
  }

  &.is-warning {
    background-color: $warning-extralight;
  }

  &.is-danger {
    background-color: $danger-extralight;
  }

  &.is-info {
    background-color: $info-extralight;
  }
}

.alert-icon {
  .alert.is-success & {
    color: $success-dark;
  }

  .alert.is-warning & {
    color: $warning-dark;
  }

  .alert.is-danger & {
    color: $danger-dark;
  }

  .alert.is-info & {
    color: $info-dark;
  }
}

.alert-content {
  margin-left: $spacing-xl;
  margin-right: $spacing-xxxl;
}

.alert-title {
  display: block;
  margin-bottom: $spacing-xs;
  font-weight: 600;
  line-height: 20px;
}

.alert-close {
  position: absolute;
  top: $spacing-xl;
  right: $spacing-xl;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $main-text-color;
  transition: $transition;

  &:hover {
    color: $danger-medium;
  }

  svg {
    object-fit: contain;
  }
}

//Toastify overrides
.toasts {
  .Toastify & {/* stylelint-disable-line */
    width: 400px;
    padding-top: 60px;
  }
}

.toast {
  .toasts & {
    padding: 0;
    min-height: auto;
    box-shadow: 0 8px 12px rgb(9 30 66 / 15%), 0 0 1px rgb(9 30 66 / 31%);
  }
}

.toast-body {
  .toasts & {
    padding: 0;
  }
}
