@import "../../../../styles/abstracts/variables/variables";

.section-switcher {
  @media (max-width: $breakpoint-xs-max) {
    flex: 1 1 100%;
    width: 100%;
  }
}

.switcher {
  display: flex;
  padding: 2px;
  box-shadow: (0 4px 5px rgb(0 0 0 / 3%), 0 1px 10px rgb(0 0 0 / 3%), 0 2px 4px rgb(0 0 0 / 3%));
  border: 1px solid #e8e8e8;
  background-color: $light;
  border-radius: $radius-sm;
  overflow: hidden;

  @media (max-width: $breakpoint-xs-max) {
    flex: 1 1 100%;
    width: 100%;
  }
}

.switcher-item {
  display: flex;

  @media (max-width: $breakpoint-sm-max) {
    flex: 1 1 auto;
  }
}

.switcher-link {
  display: block;
  min-width: 87px;
  padding: $spacing-xs $spacing-xxl;
  text-align: center;
  font-weight: 500;
  font-size: $font-md;
  line-height: 1.3;
  border-radius: $radius-sm;
  transition: $transition;
  cursor: pointer;

  &.is-active {
    background-color: $primary;
    color: $light;
  }

  &:hover:not(.is-active) {
    color: $primary-dark;
  }

  @media (max-width: $breakpoint-sm-max) {
    width: 100%;
    padding: $spacing-sm $spacing-xxl;
  }
}
