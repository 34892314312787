@import "../../styles/abstracts/variables/variables";

.auth {
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100vh;
}

.auth-banner {
  display: none;
  flex: 0 0 auto;
  width: 40%;
  justify-content: flex-end;
  max-width: 740px;
  padding-top: 80px;
  background-image: url("./img/physician.png");
  background-size: cover;
  background-repeat: no-repeat;

  @media (min-width: $breakpoint-md) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.auth-headliner {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: -230px;
  min-height: 0%;
  align-items: center;
  object-fit: contain;
  margin-bottom: 20px;

  h3{
    font-size: 20px;
    font-weight: 700;
  }

}

.auth-headliner-img {
  margin: 0 auto 20px auto;
  max-width: 100%;
  max-height: 100%;
}

.auth-logo {
  margin: 0 $spacing-xxxl 200px;
}

.auth-logo-img {
  max-width: 100%;
  object-fit: contain;
}

.auth-content {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  padding: 84px 0;
  overflow: auto;

  @media (min-width: $breakpoint-md) {
    padding: 0 0 60px;
  }
}

.auth-title {
  margin-bottom: $spacing-lg;

  @media (min-width: $breakpoint-md) {
    margin-bottom: $spacing-xxl;
  }
}
