//titles

.title-xxl {
  font-size: 28px;
  font-weight: 400;

  @media (min-width: $breakpoint-md) {
    font-size: $font-xxl;
    font-weight: 500;
  }
}

.title-xl {
  font-size: $font-xl;
  font-weight: bold;
}

.title-lg {
  font-size: $font-lg;
  font-weight: bold;
}

.title-md {
  font-size: $font-lg;
  font-weight: 500;
}

//paragraphs

%text {
  & + & {
    margin-top: 20px;
  }
}

.text-xl {
  @extend %text;

  font-size: $font-xl;
}

.text-lg {
  @extend %text;

  font-size: $font-lg;
}

.text-md {
  @extend %text;

  font-size: $font-md;
  line-height: 1.6;

  @media (min-width: $breakpoint-md) {
    font-size: 18px;
  }
}

.text {
  @extend %text;

  font-size: $font-sm;
}

//boldness
.text-bold {
  font-weight: 700;
}

.text-semibold {
  font-weight: 500;
}

//styles
.text-italic {
  font-style: oblique;
}

//colors
.text-primary {
  color: $primary;
}

//alignment
.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

//transform
.text-uppercase {
  text-transform: uppercase;
}
