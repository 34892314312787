@import "../../styles/abstracts/variables/variables";

$unavailable-color: #eee;

%cell {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin-right: $spacing-md;
  font-weight: 500;
  border-radius: $radius-sm;
}

.calendar-legend {
  margin: -($spacing-xs / 2) 0;
}

.calendar-legend-item {
  display: flex;
  align-items: center;
  padding: ($spacing-xs / 2) 0;
}

.selected-day {
  @extend %cell;

  background-color: $primary;
  color: $light;
}

.unavailable-days {
  @extend %cell;

  background-color: $unavailable-color;
  color: $secondary-text-color;
}

.available-days {
  @extend %cell;

  border: 1px solid $unavailable-color;
}

.day-label {
  display: flex;
  align-items: center;
  font-size: $font-sm;
  font-weight: 500;
  margin-bottom: $spacing-xs;
}
