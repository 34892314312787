@import "../../styles/abstracts/variables/variables";
@import "../../styles/base/helpers";

%search-icon {
  cursor: pointer;
  transition: $transition;
}

.search-field {
  display: flex;
  position: relative;
  width: 100%;
  overflow: hidden;
}

.search-prepend-icon {
  @extend %field-icon;
  @extend %search-icon;

  color: $primary;
  pointer-events: none;
}

.search-append-icon {
  @extend %field-icon;
  @extend %search-icon;

  display: none;
  left: auto;
  right: 0;

  &:hover {
    color: $danger-medium;
  }

  .search.is-open & {
    display: flex;
  }
}

.search-control {
  @extend %field-control;

  &::-webkit-search-cancel-button {
    display: none;
  }

  &::-ms-clear {
    display: none;
  }

  .search-append-icon ~ & {
    padding-right: 0;
  }

  .search-prepend-icon ~ & {
    padding-left: 44px;
  }

  .search.is-open & {
    @media (min-width: $breakpoint-sm) {
      max-width: 340px;
    }
  }

  .search.is-open .search-append-icon ~ & {
    padding-right: 44px;
  }

  @media (min-width: $breakpoint-sm) {
    max-width: 44px;
  }
}
