@import "../../styles/abstracts/variables/variables";

%status {
  display: inline-block;
  padding: 0 $spacing-sm;
  font-weight: 500;
  font-size: $font-sm;
  line-height: 17px;

  svg {
    width: 100%;
    height: 100%;
  }

  .integrations & {
    padding: 0;
  }
}

.status {
  @extend %status;

  &.is-success {
    color: $success-dark;
  }

  &.is-warning {
    color: $warning-dark;
  }

  &.is-danger {
    color: $danger-dark;
  }

  &.is-info {
    color: $info-dark;
  }
}

.status-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.status-icon {
  margin-right: $spacing-xs;
  width: 20px;
  height: 20px;
}
