@import "../../styles/abstracts/variables/variables";

%info {
  display: flex;
  align-items: flex-start;
  padding: $spacing-md $spacing-xl;
  border-radius: $radius-sm;
}

.info {
  @extend %info;

  &.is-success {
    color: $success-dark;
    background-color: $success-extralight;
  }

  &.is-warning {
    color: $warning-dark;
    background-color: $warning-extralight;
  }

  &.is-danger {
    color: $danger-dark;
    background-color: $danger-extralight;
  }

  &.is-info {
    color: $info-dark;
    background-color: $info-extralight;
  }
}

.info-icon {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
}

.info-content {
  display: inline;
  font-size: $font-md;
  margin-left: $spacing-sm;
}

.info-content-phone {
  white-space: nowrap;
  font-weight: 600;
  margin-left: $spacing-sm;
}
