@import "../../styles/abstracts/variables/variables";
@import "../../styles/base/helpers";

.input-prepend-icon {
  @extend %field-icon;
}

.input-append-icon {
  @extend %field-icon;

  left: auto;
  right: 0;
}

.input-control {
  @extend %field-control;

  .input-append-icon ~ & {
    padding-right: 50px;
  }

  .input-prepend-icon ~ & {
    padding-left: 50px;
  }
}

.input-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: $transition;

  &:hover {
    color: $primary-medium;
  }
}

.input-control [class*="-container"] {
  @extend %field-control;

  .input-append-icon ~ & {
    padding-right: 50px;
  }

  .input-prepend-icon ~ & {
    padding-left: 50px;
  }
}
