@import "../../../../styles/abstracts/variables/variables";

.integrations {
  font-size: $font-md;
}

.integrations-item {
  padding: $spacing-lg;
  border: 1px solid $grey-light;
  border-radius: $radius;
  background: $light;

  & + & {
    margin-top: $spacing-lg;
  }

  @media (min-width: $breakpoint-sm) {
    flex-wrap: nowrap;
  }
}

.integrations-heading {
  display: flex;
  flex-wrap: wrap;
}

.integrations-icon {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  margin-top: -4px;
  border-radius: $radius-sm;
  box-shadow: 0 4px 4px rgb(137 137 137 / 25%);

  @media (min-width: $breakpoint-sm) {
    margin-right: $spacing-lg;
  }
}

.integrations-status {
  flex: 1 1 auto;
  order: -1;

  @media (min-width: $breakpoint-sm) {
    margin-left: $spacing-lg;
    flex: 0 0 auto;
    order: 0;
  }
}

.integrations-title {
  width: 100%;
  display: block;
  flex: 1 1 auto;
  margin-bottom: $spacing-md;
  font-size: 18px;
  font-weight: normal;
  line-height: 22px;

  @media (min-width: $breakpoint-sm) {
    width: auto;
  }
}

.integrations-text {
  margin-bottom: $spacing-sm;
  line-height: 19px;
  color: $secondary-text-color;
}

.integrations-controls {
  display: flex;
}

.integrations-control {
  & ~ & {
    flex: 0 0 auto;
    margin-left: $spacing-lg;
  }
}
