//visually hidden, but available for screen readers
%visually-hidden {
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip: rect(0, 0, 0, 0);
  position: absolute;
}

.visually-hidden {
  @extend %visually-hidden;
}

//basic form control
%field-content {
  position: relative;
  width: 100%;
}

//input, textarea basic control
%field-control {
  display: block;
  width: 100%;
  height: 44px;
  border: 1px solid #d0d5dd;
  background-color: #fff;
  padding: $font-xxs $font-sm;
  color: inherit;
  font-family: $font-family;
  font-size: $font-md;
  font-weight: 400;
  line-height: 1.14;
  border-radius: $radius;
  box-shadow: 0 1px 2px rgb(16 24 40 / 5%);
  transition: $transition;
  -webkit-appearance: none;

  &::placeholder,
  &::-webkit-input-placeholder {
    font: inherit;
    font-size: $font-md;
    font-weight: 400;
    color: $secondary-text-color;
  }

  &.disabled,
  &:disabled {
    pointer-events: none;
    cursor: default;
    background-color: #f9fafb;
    color: $secondary-text-color;
  }

  &:not(:disabled):not(.disabled):focus,
  &:not(:disabled):not(.disabled):hover {
    border-color: $primary-medium;
  }

  &.has-error,
  .field.has-error & {
    border-color: $danger-medium;
  }
}

%field-icon {
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  width: 44px;
}

//input, textarea basic control
%field-control[class*="-container"] {
  padding: 0;
  height: 100%;

  .css-1s2u09g-control {
    border: none;
    outline: none;
    border-radius: $radius !important;
  }

  &.has-error,
  .field.has-error[class*="-container"] & {
    border-color: $danger-medium;
  }
}
