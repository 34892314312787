@import "../../../../styles/abstracts/variables/variables";

.notifications {
  position: relative;
  padding: 0 $spacing-sm;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;

  @media (min-width: $breakpoint-md) {
    padding: 0 $font-sm;
  }
}

.notifications-control {
  position: relative;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 100%;

  &:hover {
    background-color: $grey-light;
  }
}

.notifications-amount {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 15px;
  min-width: 15px;
  position: absolute;
  left: 100%;
  top: 0;
  background-color: $primary;
  color: $light;
  padding: 0 3px;
  line-height: 1;
  border-radius: $radius;
  transform: translate(-50%, -50%);
  font-size: $font-xs;
}

.notifications-tooltip {
  display: none;
  margin: 8px 0;
  position: absolute;
  top: 100%;
  right: 0;
  border: 1px solid #d0d5dd;
  background-color: #fff;
  padding: $font-xxs $font-sm;
  border-radius: $radius;

  @media (min-width: $breakpoint-md) {
    white-space: nowrap;
  }

  .notifications-control:hover ~ & {
    display: block;
  }
}
