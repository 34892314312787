@import "../../../../styles/abstracts/variables/variables";

.header {
  position: sticky;
  top: 0;
  display: flex;
  padding: $spacing-sm 0;
  background-color: $light;
  box-shadow: 0 0 4px 4px rgb(9 30 66 / 13%);
  z-index: 9;

  @media (min-width: $breakpoint-md) {
    padding: 2px 0;
  }

  .header-privacy {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    @media (min-width: $breakpoint-md) {
      position: relative;
      padding: $spacing-xl 0;
      background-color: transparent;
      box-shadow: none;
    }
  }
}

.header-content {
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}

.header-content-privacy {
  width: 100%;
  display: flex;
  align-items: stretch;
}
.header-controls {
  display: flex;
  align-items: center;
  margin-left: auto;
}


.nav-privacy {

  @media (min-width: $breakpoint-lg) {
    display: block;
  }
}

.nav-list {
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0 $spacing-xxxl;
}

.nav-item {
  height: 100%;
  font-weight: 500;

  & + & {
    @media (min-width: $breakpoint-sm) {
      margin-left: $spacing-xl;
    }

    @media (min-width: $breakpoint-lg) {
      margin-left: $spacing-xxl;
    }
  }
}

.nav-link {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  transition: $transition;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: $spacing-xs;
    right: $spacing-xs;
    transform: translateY(100%);
    height: 2px;
    max-width: 0;
    background-color: $primary;
    transition: $transition;
  }

  &.is-active::after {
    max-width: 100%;
  }
}

.nav-link-label {
  padding: $spacing-sm;
  border-radius: 3px;
  transition: $transition;

  .nav-link:hover & {
    background-color: $primary-extralight;
    color: $primary-medium;
  }
}
