@import "../../../../styles/abstracts/variables/variables";

.link-back {
  margin-bottom: $spacing-xxxl;
  background-color: transparent;
  border: 0;
  box-shadow: none;

  @media (min-width: $breakpoint-md) {
    margin-bottom: 80px;
  }
}
