@import "../../../../styles/abstracts/variables/variables";

.nav {
  display: none;

  @media (min-width: $breakpoint-lg) {
    display: block;
  }
}

.nav-list {
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0 $spacing-xxxl;
}

.nav-item {
  height: 100%;
  font-weight: 500;

  & + & {
    @media (min-width: $breakpoint-sm) {
      margin-left: $spacing-xl;
    }

    @media (min-width: $breakpoint-lg) {
      margin-left: $spacing-xxl;
    }
  }
}

.nav-link {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  transition: $transition;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: $spacing-xs;
    right: $spacing-xs;
    transform: translateY(100%);
    height: 2px;
    max-width: 0;
    background-color: $primary;
    transition: $transition;
  }

  &.is-active::after {
    max-width: 100%;
  }
}

.nav-link-label {
  padding: $spacing-sm;
  border-radius: 3px;
  transition: $transition;

  .nav-link:hover & {
    background-color: $primary-extralight;
    color: $primary-medium;
  }
}
