@import "../../styles/abstracts/variables/variables";

.breadcrumbs {
  display: flex;
  margin: 0 (-$spacing-xs) $spacing-xxl;
}

.breadcrumbs-item {
  padding: 0 $spacing-xs;
  color: #9e9e9e;
  cursor: default;

  &:last-child {
    color: #464646;
  }

  &:not(:last-child)::after {
    content: "/";
  }
}

.breadcrumbs-link {
  margin-right: $spacing-sm;
  transition: $transition;

  &:hover {
    color: $primary-medium;
  }
}
