@import "../../../../styles/abstracts/variables/variables";

.filters {
  padding: $spacing-xl;
  background-color: $light;
  box-shadow: 0 0 0 1px rgb(152 161 179 / 10%), 0 15px 35px -5px rgb(17 24 38 / 20%), 0 5px 15px rgb(0 0 0 / 8%);
  border-radius: $radius-sm;
}

.filters-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: $spacing-lg;
}

.filters-controls {
  display: flex;
  align-items: center;
  margin-left: $spacing-xl;
}

.filters-control {
  & + & {
    margin-left: $spacing-lg;

    @media (min-width: $breakpoint-md) {
      margin-left: $spacing-xxl;
    }
  }
}
