@import "../../../../styles/abstracts/variables/variables";

.header-auth {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9;

  @media (min-width: $breakpoint-md) {
    position: relative;
    padding: $spacing-xl 0;
    background-color: transparent;
    box-shadow: none;
  }
}

.header-auth-logo {
  @media (min-width: $breakpoint-md) {
    display: none;
  }
}
