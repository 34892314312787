.form-section {
  & + & {
    margin-top: $spacing-xl;
  }
}

.form-footer {
  margin-top: $spacing-xxl;

  .panel & {
    display: flex;
    margin-top: 60px;

    @media (min-width: $breakpoint-md) {
      margin-top: 72px;
    }
  }
}

.form-title {
  margin-bottom: $spacing-md;

  &.is-underlined {
    padding-bottom: $spacing-xxl;
    margin-bottom: $spacing-xxl;
    margin-top: $spacing-xxl;
    border-bottom: 1px solid $grey-dark;

    .form-section:not(:first-of-type) & {
      margin-top: 60px;

      @media (min-width: $breakpoint-md) {
        margin-top: 72px;
      }
    }
  }
}

.form-group {
  width: 100%;
  display: flex;
  align-items: center;
}

.form-group-item {
  flex: 1 1 auto;

  & + & {
    flex: 0 0 auto;
    margin-left: $spacing-md;
  }
}

.form-field {
  & + & {
    margin-top: $spacing-xl;
  }
}
