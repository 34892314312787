::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 1px 0 rgb(134 143 160 / 60%);
  background-color: $grey-extralight;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: $grey-extralight;
}

::-webkit-scrollbar-thumb {
  border-radius: $radius-xs;
  background-color: $grey-medium;
}
