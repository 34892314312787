@import "../../styles/abstracts/variables/variables";

%label {
  display: inline-block;
  position: relative;
  padding: 0 $spacing-sm;
  margin-left: 5px;
  border-radius: $radius;
  font-weight: 500;
  font-size: $font-xs;
  line-height: 20px;
}

.label {
  @extend %label;

  &.is-success {
    color: $success-dark;
    background-color: $success-extralight;
  }

  &.is-warning {
    color: $warning-dark;
    background-color: $warning-extralight;
  }

  &.is-danger {
    color: $danger-dark;
    background-color: $danger-extralight;
  }

  &.is-info {
    color: $info-dark;
    background-color: $info-extralight;
  }

  .checkbox ~ &,
  .section-title & {
    margin-left: $spacing-sm;
  }
}

.label-content {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;

  &::before {
    content: "";
    display: block;
    flex: 0 0 auto;
    width: 6px;
    height: 6px;
    margin-right: 6px;
    background-color: currentcolor;
    border-radius: 100%;
  }
}
