@import "../../styles/abstracts/variables/variables";

$table-border: #e9e9e9;
$table-row-hover: #fbfbfb;

.table {
  overflow: auto;
  margin: 0 (-$spacing-md);

  .card {
    margin: 0;
  }

  @media (min-width: $breakpoint-md) {
    margin: 0;
  }
}

.table-content {
  min-width: 100%;
  table-layout: fixed;
  border-spacing: 0;
  border: 1px solid $table-border;

  .table.is-empty & {
    display: none;

    @media (min-width: $breakpoint-sm) {
      display: table;
    }
  }
}

.table-placeholder {
  @media (min-width: $breakpoint-sm) {
    display: none;
  }
}

.table-row {
  cursor: pointer;

  .table-header & {
    text-align: left;
  }
}

.table-cell {
  //width: 0%;
  min-height: 44px;
  padding: $spacing-sm $spacing-md;
  white-space: nowrap;
  background-color: $light;

  &.is-sticky {
    position: sticky;
    right: 0;
    width: 106px;

    @media (min-width: $breakpoint-sm) {
      width: 138px;
    }
  }

  .table-header & {
    font-weight: 400;
    font-size: $font-sm;
    line-height: 24px;
    background-color: #edeaff;
    position: sticky;
    top: 0;
    z-index: 3;
  }

  & + & {
    border-left: 1px solid $table-border;
  }

  .card &:first-child,
  .card &:last-child {
    padding: $spacing-sm $spacing-xl;

    @media (min-width: $breakpoint-md) {
      padding: $spacing-sm $spacing-xxxl;
    }
  }

  .table-row + .table-row & {
    border-top: 1px solid $table-border;
  }

  .table-body .table-row:hover & {
    background-color: $table-row-hover;
  }
}

.table-cell-placeholder {
  font-size: $font-xs;
  font-weight: 400;
  color: $secondary-text-color;
}

.table-heading {
  display: flex;
  align-items: center;

  .table-actions & {
    justify-content: flex-end;
  }
}

.table-actions-list {
  display: flex;
  margin-left: $spacing-sm;
  justify-content: flex-end;
}

.table-sorting {
  display: flex;
  flex-direction: column;
}

.table-sorting-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 10px;
  color: $grey-dark;
  transition: $transition;

  .table-cell.is-asc & {
    &.is-asc {
      color: $grey-extradark;
    }

    &.is-desc {
      color: $grey-dark;
    }
  }

  .table-cell.is-desc & {
    &.is-asc {
      color: $grey-dark;
    }

    &.is-desc {
      color: $grey-extradark;
    }
  }

  .table-cell:hover & {
    &.is-asc {
      color: $secondary-text-color;
    }

    &.is-desc {
      color: $secondary-text-color;
    }
  }

  .table-cell.is-asc:hover & {
    &.is-asc {
      color: $grey-dark;
    }

    &.is-desc {
      color: $secondary-text-color;
    }
  }

  .table-cell.is-desc:hover & {
    &.is-asc {
      color: $secondary-text-color;
    }

    &.is-desc {
      color: $grey-dark;
    }
  }

  svg {
    width: 100%;
    object-fit: contain;
  }
}

.table-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 400px;
  padding: $spacing-xxxl;
  color: $secondary-text-color;

  @media (min-width: $breakpoint-sm) {
    background-color: $light;
  }
}

.table-empty-icon {
  margin-bottom: $spacing-md;
}

//show status near name for mobile
.table-name {
  display: flex;
  align-items: center;
}

.table-status {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 100%;
  margin-right: $spacing-xs;

  &.is-success {
    background-color: $success-dark;
  }

  &.is-warning {
    background-color: $warning-dark;
  }

  &.is-danger {
    background-color: $danger-dark;
  }

  &.is-info {
    background-color: $info-dark;
  }

  @media (min-width: $breakpoint-md) {
    display: none;
  }
}

.table-cell-registration-status {
  @media (max-width: $breakpoint-md) {
    display: none;
  }
}

//legend
.table-legend {
  margin-bottom: $spacing-md;

  @media (min-width: $breakpoint-md) {
    display: none;
  }
}

.table-legend-title {
  display: block;
  margin-bottom: $spacing-xs;
  font-size: $font-xs;
  font-weight: 500;
}

.table-legend-list {
  display: flex;
  flex-wrap: wrap;
  margin: (-$spacing-xs / 2);
}

.table-legend-item {
  padding: $spacing-xs / 2;
}

//working-hours
.table-working-hours {
  font-size: $font-xs;
}
