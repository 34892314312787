*,
*::before,
*::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  min-height: 100%;
}

body {
  padding: 0;
  margin: 0;
  font-family: $font-family;
  font-size: $font-sm;
  line-height: 1.43;
  color: $main-text-color;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul {
  list-style: none;
}

*,
*:active,
*:focus {
  outline: none;
}

a {
  text-decoration: none;
  color: inherit;
}

.pagecontent {
  height: 100vh;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.main {
  overflow-y: auto;
  position: relative;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  min-height: 0%;
}

.main-content {
  overflow: auto;
  padding: $spacing-xxl 0;
  margin: -$spacing-xxl 0;
}

.content {
  flex: 1 1 auto;
  min-height: 0%;

  @media (min-width: $breakpoint-md) {
    display: flex;
  }
}

.sidebar {
  flex: 0 0 auto;
  padding-bottom: $spacing-xxl;
  background-color: #fafafa;

  @media (min-width: $breakpoint-md) {
    overflow: auto;
    padding: $spacing-xxl 0;
    margin: -$spacing-xxl 0;
  }
}

.sidebar-content {
  padding: 0 $spacing-lg;

  @media (min-width: $breakpoint-sm) {
    display: flex;
  }

  @media (min-width: $breakpoint-md) {
    display: block;
    padding: 0 $spacing-xxxl;
  }
}

.sidebar-row {
  & + & {
    margin-top: $spacing-xxxl;

    @media (min-width: $breakpoint-sm) {
      margin-top: 0;
      margin-left: $spacing-xxxl;
      flex: 1 1 auto;
    }

    @media (min-width: $breakpoint-md) {
      margin-top: $spacing-xxxl;
      margin-left: 0;
    }
  }
}

.sidebar-filters {
  display: none;

  @media (min-width: $breakpoint-sm) {
    display: block;
  }
}
