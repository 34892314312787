@import "../../../../../../styles/abstracts/variables/variables";

.working-hours-list {
  display: table;
  width: 100%;
}

.working-hours-item {
  display: table-row;
}

.working-hours-col {
  display: table-cell;
  padding: $spacing-md 0;
  border-bottom: 1px solid #e4e8ef;
  vertical-align: top;
}

.working-hours-actions {
  display: flex;
  justify-content: flex-end;
}

.working-hours-intervals {
  width: 100%;
  padding-left: $spacing-xl;
}

.working-hours-inactive,
.working-hours-checkbox {
  min-height: 44px;
  display: flex;
  align-items: center;
}

.working-hours-inactive {
  color: $secondary-text-color;
}

.interval {
  display: flex;
  align-items: center;
  line-height: 44px;
}

.interval-divider {
  padding: 0 $spacing-sm;

  .filters & {
    padding: 0 $spacing-xl;
  }
}

.interval-remove {
  margin-left: auto;
  padding: 0 $spacing-sm;
}
