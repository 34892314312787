@import "../../styles/abstracts/variables/variables";

.confirm {
  text-align: center;
  padding: $spacing-xxxl;
}

.confirm-icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  margin: 0 auto $spacing-xxl;
  border-radius: 100%;
  color: $light;

  .confirm.is-success & {
    background-color: $success-dark;
  }

  .confirm.is-danger & {
    background-color: $danger-medium;
  }

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 59px;
    height: 59px;
    border-radius: 100%;
    z-index: -1;

    .confirm.is-success & {
      background-color: $success-extralight;
    }

    .confirm.is-danger & {
      background-color: $danger-extralight;
    }
  }
}

.confirm-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 1 auto;
  padding-top: $spacing-lg;
}

.confirm-content {
  margin-top: $spacing-xxl;
}

.confirm-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: $spacing-xxxl;
}

.confirm-text {
  margin-top: $spacing-xs;
  color: $secondary-text-color;
}
