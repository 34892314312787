@import "../../../../styles/abstracts/variables/variables";

.menu {
  max-height: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $light;
  transition: $transition;
  overflow: auto;
  font-size: $font-md;
  z-index: 8;

  &.is-open {
    @media (max-width: $breakpoint-lg) {
      max-height: 100vh;
      padding-top: 56px;
    }
  }
}

.menu-title {
  padding: $spacing-xl 0;
  border-bottom: 1px solid $grey-dark;
}

.menu-item {
  font-weight: 500;
  border-bottom: 1px solid $grey-dark;
}

.menu-link {
  display: block;
  width: 100%;
  padding: $spacing-xl 0;
  background-color: transparent;
  border: 0;
  box-shadow: none;
  color: inherit;
  text-align: left;
  font: inherit;
  cursor: pointer;

  &.is-active {
    color: $primary;
  }

  &.menu-logout {
    color: $danger-medium;
  }
}

.menu-link-label {
  display: block;
}
