@import "../../../../styles/abstracts/variables/variables";
@import "../../../../styles/base/helpers";

.verification {}

.verification-text {
  white-space: pre-wrap;
  margin-bottom: $spacing-xl;
}

.verification-form {
  margin-bottom: $spacing-xl;

  :where(.vi__wrapper) {
    width: 100%;
  }

  :where(.vi__container) {/* stylelint-disable-line */
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-basis: 100%;
    height: auto;
    gap: 6px;

    @media (min-width: $breakpoint-md) {
      gap: 13px;
    }
  }

  :where(.vi__character) {/* stylelint-disable-line */
    @extend %field-control;

    font-size: $font-xl;
    font-weight: 500;
    width: 41px;
    height: 100%;
    line-height: 21px;
    border-radius: 16px;

    @media (min-width: $breakpoint-md) {
      width: 74px;
      line-height: 54px;
    }
  }

  :where(.vi__character--selected) {/* stylelint-disable-line */
    outline: none;
    box-shadow: 0 0 0 1px $primary-medium;
    border: 1px solid $primary-medium;
  }
}
