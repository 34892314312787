@import "../../styles/abstracts/variables/variables";
@import "../../styles/base/helpers";

.select {
  text-align: left;

  .working-hours-list & {
    min-width: 124px;
  }
}

.select-control {
  @extend %field-control;

  height: 100%;
}

.select__input { /* stylelint-disable-line */
  .select & {
    height: 100%;
    margin-left: -2px !important;
    min-width: 50px !important;

    &::placeholder,
    &::-webkit-input-placeholder {
      font: inherit;
      font-size: $font-sm;
      font-weight: 500;
      color: $secondary-text-color;
    }
  }
}

.select__menu { /* stylelint-disable-line */
  .select & {
    width: auto;
    min-width: 100%;
    background: $light;
    border: 1px solid $grey-medium;
    box-shadow: 2px 4px 4px rgb(210 210 210 / 25%), -2px -2px 4px rgb(210 210 210 / 25%);
    border-radius: $radius;
    font-weight: 400;
    font-size: $font-sm;
    overflow: hidden;
  }

  .profile-menu & {
    right: 0;
    left: auto;
    min-width: 158px;
  }

  .filter & {
    @media (max-width: $breakpoint-xs-max) {
      right: 0;
      left: auto;
    }
  }
}

.select__menu-list { /* stylelint-disable-line */
  .select & {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.select__option { /* stylelint-disable-line */
  .select & {
    padding: $font-xxs $font-sm;
    cursor: pointer;
    transition: $transition;
    background-color: transparent;
  }

  & + & {
    border-top: 1px solid $grey-medium;
  }

  .select &:active {
    background-color: transparent;
  }
}

.select__option--is-selected { /* stylelint-disable-line */
  .select &,
  .select &:active {
    background-color: transparent;
    color: $primary;
  }
}

.select__option--is-focused { /* stylelint-disable-line */
  .select &,
  .select &:active {
    background-color: #f9f9f9;
  }
}

.select__indicator-separator { /* stylelint-disable-line */
  .select & {
    display: none;
  }
}

.select__control { /* stylelint-disable-line */
  .select & {
    @extend %field-control;

    display: flex;
    padding-top: 0;
    padding-bottom: 0;
    min-height: 44px;
    height: auto;
    cursor: pointer;
  }

  .dropdown & {
    border: 0;
    box-shadow: none;
  }

  .calendar & {
    height: 32px;
    min-height: 32px;
    padding: 0 $spacing-sm;
    border-color: transparent;
    box-shadow: none;
  }

  .select &.select__control--is-disabled { /* stylelint-disable-line */
    background-color: #f9fafb;
    color: $secondary-text-color;
  }

  .select.has-error & {
    border-color: $danger-medium;
  }
}

.select__control--is-focused { /* stylelint-disable-line */
  .select &,
  .calendar &,
  .select &:hover,
  .calendar &:hover {
    border-color: $primary-medium;
  }

  .select.has-error & {
    border-color: $primary-medium;
  }
}

.select__control--menu-is-open { /* stylelint-disable-line */
  .select &,
  .calendar &,
  .select &:hover,
  .calendar &:hover {
    border-color: $primary-medium;
  }

  .select.has-error & {
    border-color: $primary-medium;
  }
}

.select__control--is-disabled { /* stylelint-disable-line */
  .calendar & {
    border-color: $grey-medium;
  }
}

.select__value-container { /* stylelint-disable-line */
  .select & {
    padding: 0;
  }
}

.select__indicator { /* stylelint-disable-line */
  .select & {
    padding: 0;
    color: $main-text-color;

    &:hover {
      color: $primary;
    }

    svg {
      width: 16px;
      height: 16px;
    }
  }

  .select__control--is-disabled & { /* stylelint-disable-line */
    color: $secondary-text-color;
  }
}

.select__indicators { /* stylelint-disable-line */
  .select & {
    margin-left: $spacing-sm;
    margin-right: -$spacing-xs;
  }
}

.select__multi-value { /* stylelint-disable-line */
  .select & {
    margin: $spacing-xs;
    background-color: #e6f8f4;
    border-radius: $radius-lg;
  }
}

.select__input-container { /* stylelint-disable-line */
  .select & {
    margin: $spacing-xs;
  }
}

.select__value-container--is-multi { /* stylelint-disable-line */
  .select &.select__value-container--has-value { /* stylelint-disable-line */
    margin: 0 -4px;
    color: $secondary-text-color;
  }
}

.select__multi-value__label { /* stylelint-disable-line */
  .select & {
    padding: $spacing-xs 0 $spacing-xs 10px;
    font-weight: 500;
    font-size: $font-sm;
    line-height: 1.3;
  }
}

.select__multi-value__remove { /* stylelint-disable-line */
  .select & {
    padding-right: 10px;
    padding-left: 2px;
  }
}

.select__multi-value__remove { /* stylelint-disable-line */
  .select &:hover {
    background-color: transparent;
    color: $danger-medium;
  }
}

.select__multi-value__remove { /* stylelint-disable-line */
  .select & svg {
    width: $font-xs;
    height: $font-xs;
  }
}

.option {
  display: flex;
  align-items: center;
}

.option-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: $spacing-sm;
}

.option-img {
  display: block;
  width: 24px;
  height: 24px;
  object-fit: contain;
  margin-right: $spacing-sm;

  .profile-menu & {
    width: 16px;
    height: 16px;
  }
}

.option-label {
  display: flex;
  align-items: center;
  min-height: 24px;
  line-height: 24px;
  white-space: nowrap;

  .option-short-label ~ & {
    @media (max-width: $breakpoint-xs-max) {
      display: none;
    }
  }
}

.option-short-label {
  display: none;

  @media (max-width: $breakpoint-xs-max) {
    display: block;
  }
}
