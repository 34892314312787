@import "../../styles/abstracts/variables/variables";

.appointment-info-card {
  background-color: $light;
  border-bottom-right-radius: $radius-lg;
  border-bottom-left-radius: $radius-lg;

  @media (min-width: $breakpoint-md) {
    margin-top: $spacing-xxl;
  }
}

.appointment-info-card-details {
  display: block;
  width: auto;
  margin-left: $spacing-lg;
  padding-bottom: $spacing-xl;
}
