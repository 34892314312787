@import "../../styles/abstracts/variables/variables";

.calendar {
  background-color: $light;
  border-radius: $radius-sm;
  border: 1px solid #d0d5dd;
  color: $secondary-text-color;
  font-weight: 500;

  .sidebar & {
    box-shadow: 0 0 0 1px rgb(152 161 179 / 10%), 0 15px 35px -5px rgb(17 24 38 / 20%), 0 5px 15px rgb(0 0 0 / 8%);
  }
}

.calendar-header {
  display: flex;
  align-items: center;
  margin-bottom: $spacing-md;
}

.calendar-dropdown {
  &:not(:last-child) {
    margin-right: $spacing-md;
  }
}

.calendar-control {
  margin-left: auto;
}

.react-datepicker__week { /* stylelint-disable-line */
  display: flex;
  justify-items: center;
  justify-content: space-around;
}

//react-datepicker overrides
.react-datepicker__month-container { /* stylelint-disable-line */
  .calendar & {
    float: none;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
}

.react-datepicker__day { /* stylelint-disable-line */
  .calendar & {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    margin: 0;
    color: $secondary-text-color;
    border-radius: $radius-sm;
  }

  .sidebar .calendar & {
    @media (min-width: $breakpoint-md) {
      width: 47px;
      height: 47px;
    }
  }

  .calendar &:not(.react-datepicker__day--excluded):not(.react-datepicker__day--disabled):not(.react-datepicker__day--selected):hover { /* stylelint-disable-line */
    background-color: $primary-extralight;
  }
}

.react-datepicker__day--selected { /* stylelint-disable-line */
  .calendar & {
    background-color: $primary !important;
    color: $light !important;
    font-weight: 500 !important;
  }

  .calendar &:hover {
    background-color: $primary-medium;
    color: $light;
  }
}

%disabled-day {
  pointer-events: none;

  .calendar & {
    background-color: #eee;
    color: $secondary-text-color;
  }

  .calendar & + & {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-left: -10px;
    padding-left: 10px;
    width: 50px;
  }

  .sidebar .calendar & + & {
    width: 57px;
  }
}

.react-datepicker__day--excluded { /* stylelint-disable-line */
  @extend %disabled-day;
}

.react-datepicker__day--excluded { /* stylelint-disable-line */
  @extend %disabled-day;
}

.react-datepicker__day--disabled { /* stylelint-disable-line */
  @extend %disabled-day;
}

.react-datepicker__day--keyboard-selected { /* stylelint-disable-line */
  .calendar &,
  .calendar &:disabled {
    background-color: #eee;
    font-weight: bold;
  }
  .calendar &:hover {
    background-color: inherit;
    font-weight: bold;
  }
}

.react-datepicker__month { /* stylelint-disable-line */
  .calendar & {
    margin: 0;
    padding: 0 $spacing-lg $spacing-lg;
  }
}

.react-datepicker__header { /* stylelint-disable-line */
  .calendar & {
    padding: $spacing-lg $spacing-lg $spacing-sm;
    background-color: $light;
    border-bottom: 0;
    font-size: $font-xs;
  }
}

.react-datepicker__day-names { /* stylelint-disable-line */
  .calendar & {
    display: flex;
    justify-items: center;
    text-transform: uppercase;
    margin-bottom: 0;
  }
}

.react-datepicker__day-name { /* stylelint-disable-line */
  .calendar & {
    flex: 1 1 auto;
    width: auto;
    margin: 0 2px;
    line-height: 18px;
    color: inherit;
  }
}

.react-datepicker__day--outside-month { /* stylelint-disable-line */
  color: transparent;
  pointer-events: none;
  visibility: hidden;

  .calendar & {
    color: transparent;
    pointer-events: none;
    visibility: hidden;
  }
}
