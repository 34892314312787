@import "../../styles/abstracts/variables/variables";

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(0 0 0 / 30%);
  z-index: 100;
  pointer-events: none;
}

.spinner {
  margin: auto;
  width: 80px;
  height: 80px;
  position: relative;
}

.spinner-circle {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;

  &::before {
    content: "";
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    background-color: $primary-dark;
    border-radius: 100%;
    -webkit-animation: circle-fade-delay 1.2s infinite ease-in-out both;
    animation: circle-fade-delay 1.2s infinite ease-in-out both;
  }
}

.circle-2 {
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);

  &::before {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
  }
}

.circle-3 {
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);

  &::before {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }
}

.circle-4 {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);

  &::before {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
  }
}

.circle-5 {
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg);

  &::before {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
  }
}

.circle-6 {
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg);

  &::before {
    -webkit-animation-delay: -0.7s;
    animation-delay: -0.7s;
  }
}

.circle-7 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);

  &::before {
    -webkit-animation-delay: -0.6s;
    animation-delay: -0.6s;
  }
}

.circle-8 {
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg);

  &::before {
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s;
  }
}

.circle-9 {
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg);

  &::before {
    -webkit-animation-delay: -0.4s;
    animation-delay: -0.4s;
  }
}

.circle-10 {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);

  &::before {
    -webkit-animation-delay: -0.3s;
    animation-delay: -0.3s;
  }
}

.circle-11 {
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg);

  &::before {
    -webkit-animation-delay: -0.2s;
    animation-delay: -0.2s;
  }
}

.circle-12 {
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg);

  &::before {
    -webkit-animation-delay: -0.1s;
    animation-delay: -0.1s;
  }
}

@-webkit-keyframes circle-fade-delay {
  0%,
  39%,
  100% { opacity: 0; }
  40% { opacity: 1; }
}

@keyframes circle-fade-delay {
  0%,
  39%,
  100% { opacity: 0; }
  40% { opacity: 1; }
}
